"use strict";

const qs = (sel) => {
  return document.querySelector(sel);
};
const qx = (sel, parent) => {
  return (parent || document).querySelectorAll(sel);
};

import Arrow from "../images/Arrow.svx";
import File from "../images/File.svx";
import Folder from "../images/Folder.svx";
import Dna from "../images/Dna.svx";

import hljs from "highlight.js/lib/core";

import r from "highlight.js/lib/languages/r";
import latex from "highlight.js/lib/languages/latex";
import markdown from "highlight.js/lib/languages/markdown";
import python from "highlight.js/lib/languages/python";
import javascript from "highlight.js/lib/languages/javascript";

hljs.registerLanguage("r", r);
hljs.registerLanguage("latex", latex);
hljs.registerLanguage("markdown", markdown);
hljs.registerLanguage("python", python);
hljs.registerLanguage("javascript", javascript);

window.Open = (el) => el.parentElement.classList.toggle("opened");

window.Links = {};

window.Load = (el) => {
  const node = window.Links[el.dataset["id"]];
  let content = '<div class="bin">Binary file</div>';

  console.log("node", node);

  if (node.content) {
    const ext =
      node.name.indexOf(".") === -1
        ? ""
        : node.name.split(".").pop().toLowerCase();
    content = node.content;
    if (ext === "r") content = hljs.highlight("r", node.content).value;
    if (ext === "rd") content = hljs.highlight("latex", node.content).value;
    if (ext === "md") content = hljs.highlight("markdown", node.content).value;
    if (ext === "py") content = hljs.highlight("python", node.content).value;
    if (ext === "js" || ext === "json")
      content = hljs.highlight("javascript", node.content).value;
  }

  qs(`[data-file="${el.dataset["proj"]}"]`).innerHTML = content;
  qx(`[data-proj="${el.dataset["proj"]}"]`).forEach((e) =>
    e.classList.remove("selected")
  );
  el.classList.add("selected");
};

const Size = (b) => {
  if (b < 1024) return `${b}B`;
  if (b < 1024 * 1024) return `${(b / 1024).toFixed(2)}KB`;
  if (b < 1024 * 1024 * 1024) return `${(b / (1024 * 1024)).toFixed(2)}MB`;
  return `${(b / (1024 * 1024 * 1024)).toFixed(2)}GB`;
};

window.FileLoad = (el) => {
  const node = window.Links[el.dataset["id"]];
  const path = location.origin + node.path.substr(1);
  qs(`[data-file="${el.dataset["proj"]}"]`).innerHTML = [
    `<a class="btn" target="_blank" href="${path}">Скачать [${Size(
      node.size
    )}]</a>`,
    `<pre class="wget">wget '${path}'</pre>`,
  ].join("");
};

function Browser(tree, proj) {
  return tree
    .map((n) => {
      let meta = [
        `<div onclick="Open(this)" class="meta">`,
        `${Folder}<div class="name">${n.name}${Arrow}</div>`,
        `</div>`,
      ].join("");

      if (n.type === "file") {
        n.ID = "K" + Math.random();
        window.Links[n.ID] = n;
        meta = `<div onclick="${
          proj === "files" ? "FileLoad(this)" : "Load(this)"
        }" data-id="${
          n.ID
        }" data-proj="${proj}" class="meta">${File}<div class="name">${
          n.name
        }</div></div>`;
      }

      return [
        `<div class="item type-${n.type} ${proj === "files" ? "opened" : ""}">`,
        `${meta}<div class="children">${
          n.children ? Browser(n.children, proj) : ""
        }</div>`,
        `</div>`,
      ].join("");
    })
    .join("");
}

qx("[data-tree]").forEach(function (tool) {
  const proj = tool.dataset["tree"];
  const wait = setInterval(() => {
    if (!window.data[proj]) return;
    clearInterval(wait);

    const tree = window.data[proj];
    tool.innerHTML = [
      '<div class="tree">' + Browser(tree, proj) + `</div>`,
      `<div data-file="${proj}" class="hljs content"></div>`,
    ].join("");

    const node =
      tree.filter((e) => e.name.toLowerCase() === "readme.md")[0] || tree[0];
    Load(qs(`[data-id="${node.ID}"]`));
  }, 100);
});

qs(".decorator").innerHTML = Dna;
